export const voucherStatusOptions = [
  {
    text: 'Active',
    value: true,
    style: { color: '#4bd963' },
  },
  {
    text: 'Inactive',
    value: false,
    style: { color: '#FFB740' },
  },
]

export const generateLifespans = function() {
  const lifespans = []
  for (let i = 1; i <= 24; i++) {
    lifespans[i - 1] = {
      value: i,
      text: `${i} hrs`,
    }
  }
  return lifespans
}

export const voucherTypes = [
  { text: 'CREDIT', value: 'RV_CREDIT' },
  { text: 'TRIP', value: 'RV_TRIP' },
]

// merge summary count with component data
export function formatSummaries(summaries) {
  const generateSummaryOptions = (title, variant, icon = null) => ({
    title,
    variant,
    icon,
  })
  const generateSummaryDetails = (summaryOption, value) => ({
    ...summaryOption,
    value,
  })

  const summaryOptions = {
    total_active_voucher: generateSummaryOptions('Active', 'green'),
    total_inactive_voucher: generateSummaryOptions('Inactive', 'gray'),
    total_expired_voucher: generateSummaryOptions('Expired', 'red'),
    total_credit_voucher: generateSummaryOptions('Credit Voucher', 'orange'),
    total_trip_voucher: generateSummaryOptions('Trip Voucher', 'purple'),
  }

  const formattedSummaries = []
  for (const k in summaryOptions) {
    formattedSummaries.push(
      generateSummaryDetails(summaryOptions[k], summaries[k])
    )
  }

  return formattedSummaries
}
