var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EdgeStack',{staticClass:"relative",attrs:{"id":_vm.esId,"header-class-name":"mb-4 mt-8 px-4"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"items-center panel-title header-title"},[_vm._v(" "+_vm._s(_vm.getTitleText)+" ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save")])]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.reqBusy,"is-full-page":false},on:{"update:active":function($event){_vm.reqBusy=$event}}}),_c('div',{staticClass:"px-6 pt-8"},[_c('VoucherCard',{ref:"voucherCard",attrs:{"type":_vm.getVoucherType,"reward":_vm.getVoucherReward,"expire-at":_vm.getExpiresAt,"title":_vm.getLifespan,"location":_vm.getLocation}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"mt-4"},[_c('AppInput',{attrs:{"type":"richselect","name":"Voucher Type","label":"Voucher Type","placeholder":"Select a voucher type","options":_vm.voucherTypes,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.voucher_type),callback:function ($$v) {_vm.$set(_vm.form, "voucher_type", $$v)},expression:"form.voucher_type"}})],1),_c('div',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"Fleet Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-label',{attrs:{"text":"Fleet Currency *","error":errors[0]}}),_c('TRichSelect',{attrs:{"options":_vm.currencies,"variant":{ danger: !!errors[0] },"placeholder":"Select fleet currency"},model:{value:(_vm.fleetCurrencyProxy),callback:function ($$v) {_vm.fleetCurrencyProxy=$$v},expression:"fleetCurrencyProxy"}}),(errors[0])?_c('input-error-item',{attrs:{"message":errors[0]}}):_vm._e()]}}],null,true)}),_c('div',{staticClass:"mt-1 text-xs text-gray-600"},[_vm._v(" You have total "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.fleetList.length || 0))]),_vm._v(" fleets with "),_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.currencies.length || 0)+" ")]),_vm._v(" unique "+_vm._s(_vm.currencies.length > 1 ? 'currencies' : 'currency')+". You can let your users use the voucher code on single or multiple fleets of same currency. ")])],1),_c('div',{staticClass:"mt-4"},[_c('text-input',{attrs:{"rules":"required","label":"Reward Amount","placeholder":"e.g. $20","errorMessage":_vm.customError.reward_amount,"errorKey":"reward_amount","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.reward_amount),callback:function ($$v) {_vm.$set(_vm.form, "reward_amount", $$v)},expression:"form.reward_amount"}})],1),_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"name":"Assigned Fleet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":"Assigned Fleet *"}}),_c('TRichSelect',{attrs:{"options":_vm.getFleetOptions,"close-on-select":false,"variant":{ danger: !!errors[0] },"placeholder":_vm.fleetCurrencyProxy
                  ? 'Select fleet(s)'
                  : 'Select a fleet currency first',"multiple":""},model:{value:(_vm.form.assigned_fleets),callback:function ($$v) {_vm.$set(_vm.form, "assigned_fleets", $$v)},expression:"form.assigned_fleets"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',{staticClass:"mt-2 w-full"},[_c('text-input',{attrs:{"rules":"required","label":"Title","placeholder":"Title","errorMessage":_vm.customError.title,"errorKey":"title"},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('div',{staticClass:"mt-2 w-full"},[_c('text-input',{attrs:{"label":"Description","placeholder":"Description","errorMessage":_vm.customError.description,"errorKey":"description"},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":"Status","label":"Status","placeholder":"Select a status type","options":_vm.voucherStatusOptions,"hide-search-box":""},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}}),_c('text-input',{attrs:{"value":_vm.form.code.toUpperCase(),"rules":"required","label":"Voucher Code","placeholder":"e.g. BIJOY16","errorMessage":_vm.customError.code,"subText":"Enter at least 10 characters.","showSubText":true,"errorKey":"code"},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              },"input":function ($event) {
                _vm.form.code = $event.toUpperCase()
              }}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('text-input',{attrs:{"rules":"required","label":"Expire Date","type":"date","min":_vm.minDate,"errorMessage":_vm.customError.expires_at,"errorKey":"expires_at"},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.expires_at),callback:function ($$v) {_vm.$set(_vm.form, "expires_at", $$v)},expression:"form.expires_at"}}),_c('text-input',{attrs:{"rules":"required","label":"Lifespan(Hours)","placeholder":"e.g. 24","errorMessage":_vm.customError.lifespan,"errorKey":"lifespan","subText":"Enter valid positive number.","showSubText":true},on:{"clear-error":function ($event) {
                _vm.customError[$event] = ''
              }},model:{value:(_vm.form.lifespan),callback:function ($$v) {_vm.$set(_vm.form, "lifespan", $$v)},expression:"form.lifespan"}})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }