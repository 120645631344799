<template>
  <EdgeStack :id="esId" class="relative" header-class-name="mb-4 mt-8 px-4">
    <loading :active.sync="reqBusy" :is-full-page="false" />

    <template #header>
      <div class="flex justify-between">
        <div class="items-center panel-title header-title">
          {{ getTitleText }}
        </div>
      </div>
    </template>

    <template #footer>
      <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
    </template>

    <div class="px-6 pt-8">
      <VoucherCard
        ref="voucherCard"
        :type="getVoucherType"
        :reward="getVoucherReward"
        :expire-at="getExpiresAt"
        :title="getLifespan"
        :location="getLocation"
      />

      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(submitForm)"
          enctype="multipart/form-data"
        >
          <div class="mt-4">
            <AppInput
              type="richselect"
              name="Voucher Type"
              label="Voucher Type"
              placeholder="Select a voucher type"
              :options="voucherTypes"
              hide-search-box
              v-model="form.voucher_type"
              rules="required"
            />
          </div>
          <div class="mt-2">
            <ValidationProvider
              name="Fleet Currency"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label text="Fleet Currency *" :error="errors[0]" />
              <TRichSelect
                v-model="fleetCurrencyProxy"
                :options="currencies"
                :variant="{ danger: !!errors[0] }"
                placeholder="Select fleet currency"
              />
              <input-error-item :message="errors[0]" v-if="errors[0]" />
            </ValidationProvider>

            <div class="mt-1 text-xs text-gray-600">
              You have total
              <span class="font-bold">{{ fleetList.length || 0 }}</span>
              fleets with
              <span class="font-bold">
                {{ currencies.length || 0 }}
              </span>
              unique
              {{ currencies.length > 1 ? 'currencies' : 'currency' }}. You can
              let your users use the voucher code on single or multiple fleets
              of same currency.
            </div>
          </div>
          <div class="mt-4">
            <text-input
              v-model="form.reward_amount"
              rules="required"
              label="Reward Amount"
              placeholder="e.g. $20"
              :errorMessage="customError.reward_amount"
              errorKey="reward_amount"
              subText="Enter valid positive number."
              :showSubText="true"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>

          <div class="mt-4">
            <ValidationProvider
              name="Assigned Fleet"
              rules="required"
              v-slot="{ errors }"
            >
              <input-label :error="errors[0]" text="Assigned Fleet *" />
              <TRichSelect
                v-model="form.assigned_fleets"
                :options="getFleetOptions"
                :close-on-select="false"
                :variant="{ danger: !!errors[0] }"
                :placeholder="
                  fleetCurrencyProxy
                    ? 'Select fleet(s)'
                    : 'Select a fleet currency first'
                "
                multiple
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mt-2 w-full">
            <text-input
              v-model="form.title"
              rules="required"
              label="Title"
              placeholder="Title"
              :errorMessage="customError.title"
              errorKey="title"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>

          <div class="mt-2 w-full">
            <text-input
              v-model="form.description"
              label="Description"
              placeholder="Description"
              :errorMessage="customError.description"
              errorKey="description"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>

          <div class="grid grid-cols-2 gap-4 mt-2">
            <AppInput
              type="richselect"
              name="Status"
              label="Status"
              placeholder="Select a status type"
              :options="voucherStatusOptions"
              hide-search-box
              v-model="form.is_active"
            />
            <!-- <VoucherAddEditStatusDropdown
              v-model="form.is_active"
              :label="'Status'"
              :options="voucherStatusOptions"
            /> -->
            <text-input
              :value="form.code.toUpperCase()"
              rules="required"
              label="Voucher Code"
              placeholder="e.g. BIJOY16"
              :errorMessage="customError.code"
              subText="Enter at least 10 characters."
              :showSubText="true"
              errorKey="code"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
              @input="
                ($event) => {
                  form.code = $event.toUpperCase()
                }
              "
            />
          </div>

          <div class="grid grid-cols-2 gap-4 mt-2">
            <text-input
              v-model="form.expires_at"
              rules="required"
              label="Expire Date"
              type="date"
              :min="minDate"
              :errorMessage="customError.expires_at"
              errorKey="expires_at"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
            <text-input
              v-model="form.lifespan"
              rules="required"
              label="Lifespan(Hours)"
              placeholder="e.g. 24"
              :errorMessage="customError.lifespan"
              errorKey="lifespan"
              subText="Enter valid positive number."
              :showSubText="true"
              @clear-error="
                ($event) => {
                  customError[$event] = ''
                }
              "
            />
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>

      <!-- prop: {{ fleetCurrency }} proxy: {{ fleetCurrencyProxy }} form: {{ form.assigned_fleets }} -->
    </div>
  </EdgeStack>
</template>

<script>
import { VoucherConfig } from '@/config/VoucherConfig'
import { deepCompareObjects } from '@/utils'
import { edgeStackEmitter } from '@/plugins/edgeStack'
import VoucherCard from '@/components/cards/VoucherCard'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import EdgeStack from '@/components/modals/EdgeStack'
// import VoucherAddEditStatusDropdown from './VoucherAddEditStatusDropdown'
import dayjs from 'dayjs'
import { voucherStatusOptions, voucherTypes } from './helpers'
import { EventBus } from '@/utils/EventBus'
export default {
  name: 'VoucherAddEdit',
  props: {
    esId: {
      type: [String, Number],
      required: false,
      default: 'VoucherAddEdit',
    },
    primaryKey: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    formData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    currencySymbol: {
      type: [String, Number],
      required: false,
      default: '$',
    },
    fleetCurrency: {
      type: [String, Number],
      required: false,
      default: '',
    },
    fleetList: {
      type: Array,
      default: () => [],
    },
    currencies: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    EdgeStack,
    VoucherCard,
    TextInput,
    InputLabel,
    // VoucherAddEditStatusDropdown,
  },
  data() {
    return {
      reqBusy: false,
      form: {
        is_active: true,
        title: '',
        description: '',
        voucher_type: '',
        reward_amount: '',
        code: '',
        lifespan: '',
        expires_at: '',
        assigned_fleets: [],
      },
      customError: {
        title: '',
        description: '',
        voucher_type: '',
        reward_amount: '',
        code: '',
        lifespan: '',
        expires_at: '',
        assigned_fleets: '',
      },
      minDate: '',
      voucherStatusOptions,
      voucherTypes,
      // fleet list is the raw data from server
      // based on fleet currency getFleetOptions() is computed
      fleetCurrencyProxy: this.fleetCurrency,
      dateFormat: {
        format: 'DD MMM YY',
      },
      currencyList: [],
    }
  },
  computed: {
    getTitleText() {
      return this.isEditing ? 'Edit Voucher' : 'Add Voucher'
    },
    getActionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    getVoucherType() {
      return this.form?.voucher_type
    },
    getLifespan() {
      let lifespan = this.form?.lifespan
      if (!lifespan) return ''
      return `${lifespan} HRS`
    },
    getLocation() {
      let fleets = this.form?.assigned_fleets
      let locations = []
      if (fleets && Array.isArray(fleets)) {
        fleets.forEach((fleet_id) => {
          let fleet = this.fleetList.find((row) => row.id === fleet_id)
          if (fleet) {
            locations.push(fleet.name)
          }
        })
      }
      return locations.join(', ')
    },
    getExpiresAt() {
      let expires_at = this.form?.expires_at
      if (expires_at && this.$dayjs().isValid(expires_at)) {
        return this.$UTCAwareTime(expires_at, this.dateFormat)
      }
      return ''
    },
    getVoucherReward() {
      const amount = this.form?.reward_amount || 0

      return this.getVoucherType === 'RV_CREDIT'
        ? `${this.fleetCurrencyProxy}${amount}`
        : this.getVoucherType === 'RV_TRIP'
        ? `${amount} min`
        : ''
    },
    getFleetOptions() {
      const fleetListFiltered = this.fleetList.filter((fleet) => {
        return this.fleetCurrencyProxy === fleet?.country?.currency_symbol
      })

      return fleetListFiltered.map((fleet) => ({
        text: `${fleet.name} (${fleet?.country?.code3})`,
        value: fleet.id,
      }))
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (Object.keys(data).length > 1) {
          this.form = { ...data }
          this.form.expires_at = this.$UTCAwareTime(data.expires_at, {
            format: 'YYYY-MM-DD',
          })
        } else {
          this.resetForm()
          this.$edgeStack.shouldNotConfirm(this.esId)
        }
      },
    },
    // notify form is dirty & user should confirm before exiting
    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (
          deepCompareObjects(this.formData, updatedFormData) ||
          deepCompareObjects(updatedFormData, this.getFormModel())
        ) {
          return
        } else {
          this.$edgeStack.shouldConfirm(this.esId)
        }
      },
    },
    fleetCurrency(updated) {
      this.fleetCurrencyProxy = updated
    },
    // reset selected fleets on changing fleet currency
    fleetCurrencyProxy(updated) {
      if (this.isEditing && updated === this.fleetCurrency) return

      if (updated) this.form.assigned_fleets = []
    },
  },
  created() {
    this.minDatecalculation()
  },
  mounted() {
    edgeStackEmitter.on(
      this.$edgeStack.getEventName('close', this.esId),
      () => {
        console.log('submit-call-close')
        this.$edgeStack.shouldNotConfirm(this.esId)
      }
    )
    EventBus.$on('fresh-sense', ($event) => {
      console.log('submit-call-fresh-sense')
      if ($event === '') {
        this.customError = {
          title: '',
          description: '',
          voucher_type: '',
          reward_amount: '',
          code: '',
          lifespan: '',
          expires_at: '',
          assigned_fleets: '',
        }
        this.fleetCurrencyProxy = []
      }
    })
  },
  methods: {
    minDatecalculation() {
      this.minDate = dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    },
    onDirty() {
      this.$edgeStack.shouldConfirm(this.esId)
    },
    submit() {
      this.$refs.submitButton.click()
    },
    async submitForm() {
      console.log('submit-call')
      let formProxy = { ...this.form }

      formProxy.expires_at = formProxy.expires_at + 'T00:00:00'
      formProxy.description = formProxy.description || ''
      formProxy.reward_amount = this.form.reward_amount
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? VoucherConfig.api.update(this.primaryKey)
        : VoucherConfig.api.create

      const formData = new FormData()
      // todo: make a form data converter function
      for (const key in formProxy) {
        if (Array.isArray(formProxy[key])) {
          formProxy[key].forEach((el) => {
            formData.append(key, el)
          })
          continue
        }
        formData.append(key, formProxy[key])
      }

      const message = this.isEditing
        ? 'Voucher updated successfully'
        : 'Voucher added successfully'

      this.reqBusy = true
      try {
        const res = await this.$http({ method, url, data: formData })

        // emit save event
        this.$emit('save', res.data)

        // Close ES
        this.$edgeStack.close(this.esId)
        // Reset the form
        this.$edgeStack.emitter.on(
          this.$edgeStack.getEventName('closed', this.esId),
          () => {
            this.resetForm()
            // this.$edgeStack.shouldNotConfirm(this.esId)
          }
        )

        this.$edgeStack.shouldNotConfirm(this.esId, 'save_clicked')

        this.$notify({
          group: 'generic',
          type: 'success',
          title: 'Success',
          text: message,
        })
      } catch (err) {
        const asCustomErrorArray = Object.entries(this.customError)
        const filteredKeyArray = asCustomErrorArray.filter(([key, value]) => {
          console.log(value)
          return err.response.data.code.endsWith(key)
        })
        if (filteredKeyArray.length > 0) {
          this.customError[filteredKeyArray[0][0]] = err.response.data.message
        } else {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: err.response.data,
            },
            10000
          )
        }
      } finally {
        this.reqBusy = false
        this.$edgeStack.shouldNotConfirm(this.esId, 'save_clicked')
      }
    },
    getFormModel() {
      return {
        is_active: true,
        title: '',
        description: '',
        voucher_type: '',
        reward_amount: '',
        code: '',
        lifespan: '',
        expires_at: '',
        assigned_fleets: '',
      }
    },
    resetForm() {
      this.form = { ...this.getFormModel() }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
