<template>
  <base-layout>
    <VoucherAddEdit
      :es-id="ae.esId"
      :primary-key="ae.primaryKey"
      :form-data="ae.formData"
      :is-editing="ae.isEditing"
      :fleet-currency="ae.fleetCurrency"
      :currency-symbol="ae.currencySymbol"
      :fleetList="fleetList"
      :currencies="currencies"
      @save="
        () => {
          $edgeStack.shouldNotConfirm(ae.esId)
          $store.dispatch('fsTable/fetchData')
        }
      "
      @close="$edgeStack.shouldNotConfirm(ae.esId)"
    />

    <NotifyRider
      :esId="details.esId"
      :busy="details.busy"
      :riderData="rider"
      :rawData="details.rawData"
      :fleets="fleets"
      :primaryKey="details.primaryKey"
      :fleetList="fleetList"
      :currencies="currencies"
      @edgestack="
        onEdgeStack({ esId: addEdit.esId, primaryKey: $event.taskId })
      "
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            title="Voucher Management"
            @plus="onOpenES"
            :hide-plus="$acl.canNotCreate('voucher')"
          />

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="summary-card-container">
          <SummaryCard
            v-for="summary in getSummaries"
            :key="`SummaryCard-` + summary.title"
            :title="summary.title"
            :value="summary.value"
            :variant="summary.variant"
            :icon="summary.variant"
          />
        </div>
      </div>
      <div
        v-if="$acl.canNotView('voucher')"
        class="py-5 font-bold text-center text-gray-600"
      >
        You don't have permission to see data. Please Ask your admin to give you
        permission for this screen.
      </div>

      <FSTable
        v-else
        :qso="qso"
        :fst-id="fstId"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            textFallbackAlways
          >
            <FSTableRowItem
              :text="item.id"
              :truncate="-5"
              :to="{ name: 'VoucherDetails', params: { id: item.id } }"
            />

            <FSTableRowItem :text="item.title" />

            <FSTableRowItem>
              {{ showVoucherCredit(item) }}
            </FSTableRowItem>

            <FSTableRowItem :text="getVoucherType(item)" />
            <FSTableRowItem :text="item.code" :class-name="`text-oOrange`" />
            <FSTableRowItem>
              <div
                class="flex items-center gap-2 flex-wrap"
                v-if="item.assigned_fleets && item.assigned_fleets.length > 0"
              >
                <RouterLink
                  class="text-blue-600"
                  v-for="(fleet, key) in item.assigned_fleets"
                  :key="key"
                  :to="{ name: 'ViewFleetProfile', params: { id: fleet.id } }"
                  target="_blank"
                  >{{ fleet.name }}</RouterLink
                >
              </div>
            </FSTableRowItem>
            <FSTableRowItem>
              <FSTableRowItem>
                {{ item.expires_at | friendlyDateTime }}
                <p>
                  (<TimeAgo
                    :title="item.expires_at"
                    :datetime="item.expires_at"
                    :auto-update="60"
                  />)
                </p>
              </FSTableRowItem>
            </FSTableRowItem>

            <FSTableRowItem>
              <x-status
                :text="item.status"
                :variant="getItemVariant(item.status)"
              />
            </FSTableRowItem>

            <FSTableRowItem>
              <div
                class="flex items-center gap-1"
                v-if="item.status !== 'Expired'"
              >
                <OtoEditIcon @click="onOpenES(item.id)" />
                <!-- <MoreActions
                  :data="item"
                  @open="
                    onEdgeStack({
                      esId: details.esId,
                      primaryKey: item.id,
                      rawData: item,
                    })
                  "
                /> -->
                <button
                  v-tooltip.top="'Notify Riders'"
                  @click="
                    onEdgeStack({
                      esId: details.esId,
                      primaryKey: item.id,
                      rawData: item,
                    })
                  "
                  class="
                flex
                items-center
                justify-center
                px-2
                py-0
                font-medium
                border
                rounded-full
                shadow-sm                
                h-35px
                text-14px text-oWhite
                focus:border-border-oWhite                
                focus:outline-none
                
              "
                  :class="[
                    item.status === 'Active'
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed',
                  ]"
                >
                  <div class="flex items-center ">
                    <i class="mr-2 fas fa-paper-plane"></i>
                  </div>
                </button>
              </div>
              <p v-else></p>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import { VoucherConfig } from '@/config/VoucherConfig'
import { useEdgeStack } from '@/plugins/edgeStack'
import { formatSummaries } from './helpers'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import XStatus from '@/components/badge/XStatus'
import SummaryCard from '@/components/cards/SummaryCard'
import ContentSection from '@/components/layout/ContentSection'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
// import MoreActions from '@/composites/voucher-management/MoreActions.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import VoucherAddEdit from './VoucherAddEdit'
import NotifyRider from './NotifyRider'

import TitlePlus from '@/components/ui/TitlePlus.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon.vue'

export default {
  name: 'VoucherIndex',
  components: {
    BaseLayout,
    ContentSection,
    VoucherAddEdit,
    NotifyRider,
    SummaryCard,
    XStatus,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    TitlePlus,
    FSTableFilter,
    // MoreActions,
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total_active_voucher: 0,
          total_inactive_voucher: 0,
          total_credit_voucher: 0,
          total_trip_voucher: 0,
        },
      },
      qso: { append: '', prepend: '?' },
      fstId: 'voucherIndex',
      filterOptions: [],
      indexDataEndpoint: VoucherConfig.api.index,

      tableHeaders: [
        { text: 'Voucher ID', width: '10%', sort: 'id' },
        { text: 'Title', width: '20%', sort: 'title' },
        { text: 'Credit', width: '10%', sort: 'reward_amount' },
        { text: 'Type', width: '10%', sort: 'voucher_type' },
        { text: 'Code', width: '10%', sort: 'voucher_type' },
        { text: 'Fleets', width: '15%', sort: 'assigned_fleets' },
        { text: 'Expire Date', width: '15%', sort: 'expires_at' },
        { text: 'Status', width: '15%', sort: 'status' },
        { text: 'Actions', width: '10%', sort: null },
      ],

      // add-edit
      ae: {
        esId: 'voucherAddEdit',
        primaryKey: 0,
        isEditing: false,
        formData: {},
        fleetCurrency: '',
        currencySymbol: '$',
      },
      details: {
        esId: 'notifyRider',
        busy: false,
        rawData: null,
        vehicleOptions: null,
        primaryKey: null,
      },
      rider: {
        assigned_fleets: [],
        invitation_type: false,
        rider: '',
        selected_riders: [],
      },
      fleets: [],
      fleetList: [],
      currencies: [],
    }
  },
  methods: {
    getVoucherType(item) {
      if (item.voucher_type === 'RV_CREDIT') return 'CREDIT'
      if (item.voucher_type === 'RV_TRIP') return 'TRIP'
      return item.voucher_type
    },
    getItemVariant(data) {
      if (data === 'Active') return 'green'
      if (data === 'Inactive') return 'gray'
      return 'red'
    },
    async onEdgeStack({
      esId = this.details.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
      rawData = null,
    } = {}) {
      if (this.$acl.canNotUpdate('voucher')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'notify',
            permission: 'voucher',
          })
        )
        return
      }
      if (['Expired', 'Inactive'].includes(rawData.status)) {
        if (rawData.status === 'Expired') {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Expired Voucher!',
              text: "You can't notify riders for expired voucher.",
            },
            5000
          )
        }
        if (rawData.status === 'Inactive') {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Inactive Voucher!',
              text:
                "You can't notify riders for inactive voucher. Please activate the voucher first.",
            },
            5000
          )
        }
        return
      } else {
        const edgeStack = useEdgeStack()
        this.rider.rider = ''
        this.rider.invitation_type = false
        this.rider.assigned_fleets = []
        this.rider.selected_riders = []

        // open the edgestack first, load data asynchronally if primary key is provided
        if (esAction === 'toggle') {
          edgeStack.toggle(esId)
        } else if (esAction === 'open') {
          edgeStack.open(esId)
        } else if (esAction === 'close') {
          edgeStack.close(esId)
        } else {
          throw new Error(`Unknown esAction: ${esAction}`)
        }

        // immidiately make edgestack busy & fetch & provide data if primary key is provided
        if (primaryKey && esId === this.details.esId) {
          // view mode
          this.details.busy = true
          this.details.primaryKey = primaryKey
          this.details.rawData = rawData
          this.getFleetOptions()
        }
      }
    },
    getFleetOptions() {
      let fleets = this.details?.rawData?.assigned_fleets.map(function(row) {
        return {
          text: row.name,
          value: row.id,
        }
      })
      this.fleets = fleets
    },
    onApplyFilterDateRange,
    async generateFilterOptions() {
      let filters = [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Active', value: 'Active' },
            { text: 'Inactive', value: 'Inactive' },
            { text: 'Expired', value: 'Expired' },
          ],
          title: 'Status',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
    showVoucherCredit(voucher) {
      let prefix =
        voucher.voucher_type === 'RV_CREDIT' ? this.currencySymbol : ''
      let postfix = voucher.voucher_type === 'RV_CREDIT' ? '' : 'min'
      let amount =
        voucher.voucher_type === 'RV_CREDIT'
          ? voucher.reward_amount
          : parseInt(voucher.reward_amount)

      return `${prefix}${amount} ${postfix}`
    },
    async onOpenES(primaryKey = null) {
      if (this.$acl.canNotUpdate('voucher')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'voucher',
          })
        )
        return
      }

      this.ae.currencySymbol = this.currencySymbol

      if (primaryKey) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )

        // fetch data
        await this.$http
          .get(VoucherConfig.api.detail(primaryKey))
          .then((res) => {
            this.ae.primaryKey = res.data.id
            this.ae.isEditing = true

            // set fleet currency
            let fleet_id = res.data.assigned_fleets[0]?.id || ''

            let fleet = this.fleetList.find((row) => row.id === fleet_id)
            if (fleet) {
              this.ae.fleetCurrency = fleet?.country?.currency_symbol || ''
            }

            let data = res.data
            // map asigned fleets id
            data.assigned_fleets = res.data.assigned_fleets.map((x) => x.id)

            this.ae.formData = { ...data }

            // console.log({ da: res.data })
            // console.log({ fd: this.ae.formData })

            this.$edgeStack.open(this.ae.esId)
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        this.ae.isEditing = false
        this.ae.fleetCurrency = ''
        this.ae.primaryKey = 0
        this.ae.formData = {}
        this.$edgeStack.open(this.ae.esId)
      }
    },
    getPromoTypeChoice(choice) {
      const promoTypeChoice = {
        FR: 'Free Ride',
        FD: 'Flat Discount',
        RD: 'Rate Discount',
      }
      return choice in promoTypeChoice ? promoTypeChoice[choice] : '--'
    },

    async getFleets() {
      let { data } = await this.$http.get('/dashboard/fleets/?dropdown')
      if (data) {
        this.fleetList = data.data
        const currencyList = []
        data.data.forEach((fleet) => {
          const result = currencyList.find(
            (row) => fleet.country.code3 === row.text
          )
          if (!result) {
            currencyList.push({
              text: fleet.country.code3,
              value: fleet.country.currency_symbol,
            })
          }
        })
        this.currencies = currencyList
      }
    },
  },
  async created() {
    await this.generateFilterOptions()
    this.getFleets()
  },
}
</script>

<style></style>
